import { AlertColor, Box, CircularProgress, Divider } from '@mui/material';
import { Button, DatePicker, Grid, Modal, ModalActions, ModalTitle, Tabs, Tab, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import TreeView, { Tree } from '../../../../TreeView/TreeView';
import Connect from '../../../../Connect/Connect';

interface ManualSyncProps {
  openManualSync: boolean;
  setOpenManualSync: Dispatch<SetStateAction<boolean>>;
}

const OBJECTS_MAP = {
  '0': 'Account',
  '1': 'Contact',
  '2': 'Product',
  '3': 'UnitOfMeasure',
  '4': 'PaymentGateway',
  '5': 'PaymentTerm',
  '6': 'Invoice',
  '7': 'InvoiceItem',
  '8': 'TaxationItem',
  '9': 'PaymentMethod',
  '10': 'Payment',
  '11': 'InvoicePayment',
  '12': 'PaymentPart',
  '13': 'Subscription',
  '14': 'RatePlan',
  '15': 'RatePlanCharge',
  '16': 'SubscriptionHistory',
  '17': 'RatePlanHistory',
  '18': 'RatePlanChargeHistory',
  '19': 'RatePlanChargeTierHistory',
  '20': 'Refund',
  '21': 'RefundPart',
  '22': 'RefundInvoicePayment',
  '23': 'CreditMemo',
  '24': 'CreditMemoItem',
  '25': 'CreditMemoPart',
  '26': 'DebitMemo',
  '27': 'DebitMemoItem',
  '28': 'PaymentSchedule',
  '29': 'PaymentScheduleItem'
};

const PRODUCTS_MAP = {
  '0': 'BillingEntity',
  '1': 'Feature',
  '2': 'Product2',
  '3': 'ProductFeature',
  '4': 'ProductRatePlan',
  '5': 'ProductRatePlanCharge',
  '6': 'ProductRatePlanChargePriceSummary',
  '7': 'ProductRatePlanChargeTier',
  '8': 'ZProduct',
  '9': 'ZUnitOfMeasure'
};

const ManualSync: FC<ManualSyncProps> = ({
  openManualSync,
  setOpenManualSync
}: ManualSyncProps) => {
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [syncStartDate, setSyncStartDate] = useState(null);
  const [syncEndDate, setSyncEndDate] = useState(null);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });
  const connect: Connect = (window as any).connect;
  const dacoProxyPath = `${window.location.host.includes('local') ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2/datacon`;
  const [selectedTab, setSelectedTab] = useState(0);

  const OBJECTS: Tree = {
      id: '-1',
      value: 'SelectAll',
      label: 'Select All',
      enabled: true,
      children: [
        {
          id: '0',
          value: 'Account',
          label: 'Account',
          enabled: true,
          children: [
            {
              id: '1',
              value: 'Contact',
              label: 'Contact',
              enabled: true
            },
            {
              id: '2',
              value: 'Product',
              label: 'Product',
              enabled: true
            },
            {
              id: '3',
              value: 'UnitOfMeasure',
              label: 'Unit Of Measure',
              enabled: true
            },
            {
              id: '4',
              value: 'PaymentGateway',
              label: 'Payment Gateway',
              enabled: true
            },
            {
              id: '5',
              value: 'PaymentTerm',
              label: 'Payment Term',
              enabled: true
            },
            {
              id: '6',
              value: 'Invoice',
              label: 'Invoice',
              enabled: true,
              children: [
                {
                  id: '7',
                  value: 'InvoiceItem',
                  label: 'Invoice Item',
                  enabled: true,
                  children: [
                    {
                      id: '8',
                      value: 'TaxationItem',
                      label: 'Taxation Item',
                      enabled: true
                    }
                  ]
                }
              ]
            },
            {
              id: '9',
              value: 'PaymentMethod',
              label: 'Payment Method',
              enabled: true,
              children: [
                {
                  id: '10',
                  value: 'Payment',
                  label: 'Payment',
                  enabled: true,
                  children: [
                    {
                      id: '11',
                      value: 'InvoicePayment',
                      label: 'Invoice Payment',
                      enabled: true
                    },
                    {
                      id: '12',
                      value: 'PaymentPart',
                      label: 'Payment Part',
                      enabled: true
                    }
                  ]
                }
              ]
            },
            {
              id: '13',
              value: 'Subscription',
              label: 'Subscription',
              enabled: true,
              children: [
                {
                  id: '14',
                  value: 'RatePlan',
                  label: 'Rate Plan',
                  enabled: true,
                  children: [
                    {
                      id: '15',
                      value: 'RatePlanCharge',
                      label: 'Rate Plan Charge',
                      enabled: true
                    }
                  ]
                }
              ]
            },
            {
              id: '16',
              value: 'SubscriptionHistory',
              label: 'Subscription History',
              enabled: true,
              children: [
                {
                  id: '17',
                  value: 'RatePlanHistory',
                  label: 'Rate Plan History',
                  enabled: true,
                  children: [
                    {
                      id: '18',
                      value: 'RatePlanChargeHistory',
                      label: 'Rate Plan Charge History',
                      enabled: true,
                      children: [
                        {
                          id: '19',
                          value: 'RatePlanChargeTierHistory',
                          label: 'Rate Plan Charge Tier History',
                          enabled: true
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: '20',
              value: 'Refund',
              label: 'Refund',
              enabled: true,
              children: [
                {
                  id: '21',
                  value: 'RefundPart',
                  label: 'Refund Part',
                  enabled: true
                },
                {
                  id: '22',
                  value: 'RefundInvoicePayment',
                  label: 'Refund Invoice Payment',
                  enabled: true
                }
              ]
            },
            {
              id: '23',
              value: 'CreditMemo',
              label: 'Credit Memo',
              enabled: true,
              children: [
                {
                  id: '24',
                  value: 'CreditMemoItem',
                  label: 'Credit Memo Item',
                  enabled: true
                },
                {
                  id: '25',
                  value: 'CreditMemoPart',
                  label: 'Credit Memo Part',
                  enabled: true
                }
              ]
            },
            {
              id: '26',
              value: 'DebitMemo',
              label: 'Debit Memo',
              enabled: true,
              children: [
                {
                  id: '27',
                  value: 'DebitMemoItem',
                  label: 'Debit Memo Item',
                  enabled: true
                }
              ]
            },
            {
              id: '28',
              value: 'PaymentSchedule',
              label: 'Payment Schedule',
              enabled: true,
              children: [
                {
                  id: '29',
                  value: 'PaymentScheduleItem',
                  label: 'Payment Schedule Item',
                  enabled: true
                }
              ]
            }
          ]
        }
      ]
    };

  const PRODUCTS: Tree = {
      id: '-1',
      value: 'SelectAll',
      label: 'Select All',
      enabled: true,
      children: [
        {
          id: '0',
          value: 'BillingEntity',
          label: 'Billing Entity',
          enabled: true
        },
        {
          id: '1',
          value: 'Feature',
          label: 'Feature',
          enabled: true
        },
        {
          id: '2',
          value: 'Product2',
          label: 'Product2',
          enabled: true
        },
        {
          id: '3',
          value: 'ProductFeature',
          label: 'Product Feature',
          enabled: true
        },
        {
          id: '4',
          value: 'ProductRatePlan',
          label: 'Product Rate Plan',
          enabled: true
        },
        {
          id: '5',
          value: 'ProductRatePlanCharge',
          label: 'Product Rate Plan Charge',
          enabled: true
        },
        {
          id: '6',
          value: 'ProductRatePlanChargePriceSummary',
          label: 'Product Rate Plan Charge Price Summary',
          enabled: true
        },
        {
          id: '7',
          value: 'ProductRatePlanChargeTier',
          label: 'Product Rate Plan Charge Tier',
          enabled: true
        },
        {
          id: '8',
          value: 'ZProduct',
          label: 'ZProduct',
          enabled: true
        },
        {
          id: '9',
          value: 'ZUnitOfMeasure',
          label: 'ZUnit Of Measure',
          enabled: true
        }
      ]
    };

  return (
    <>
      { toast.show && <ToastController severity={toast.severity} message={toast.msg} /> }
      <Modal
        id='manual-sync-modal'
        open={openManualSync}
        dsOnClose={() => setOpenManualSync(false)}
        body={
          <Grid container direction='column'>
            <Grid item>
              <Typography>This action will initiate a batch sync of updated records in the given timeframe from Zuora to Salesforce. Errors may be reviewed and syncs retried on the Retry page. This may take up to 2 hours for 1 million records.</Typography>
            </Grid>
            <Divider style={{ marginTop: '20px', marginLeft: '10px' }} />
            <Grid item>
              <Grid container direction='row' alignContent='flex-start'>
                <Grid item xs='auto'>
                  <DatePicker label='Sync Start Date' value={syncStartDate} dsOnChange={(value) => setSyncStartDate(value)} required />
                </Grid>
                <Grid item xs='auto'>
                  <DatePicker label='Sync End Date' value={syncEndDate} dsOnChange={(value) => setSyncEndDate(value)} required />
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '20px', marginLeft: '10px' }} />
            <Grid item>
              <Tabs
                a11yLabel="package tab selection"
                value={selectedTab}
                dsOnChange={(_, newIndex) => {
                  setSelectedTab(newIndex);
                  setSelectedObjects([]);
                }}
                e2e="package-tab-selection"
              >
                <Tab
                  id="account-and-related-Objects-tab"
                  e2e="account-and-related-Objects-tab"
                  label="Account & Related Objects"
                  aria-controls={""}
                />
                <Tab
                  id="product-catalog-tab"
                  e2e="product-catalog-tab"
                  label="Product Catalog"
                  aria-controls={""}
                />
              </Tabs>
            </Grid>
            <Grid item>
            {selectedTab === 0 && (<TreeView name='Account and Related Objects' root={OBJECTS} setSelectedObjects={setSelectedObjects} defaultExpanded={['-1', '0']} />)}
            {selectedTab === 1 && (<TreeView name='Product Catalog' root={PRODUCTS} setSelectedObjects={setSelectedObjects} defaultExpanded={['-1', '0']} />)}
            </Grid>
          </Grid>
        }
        header={
          <ModalTitle dsOnClose={() => setOpenManualSync(false)}>Manual Sync</ModalTitle>
        }
        footer={
          <ModalActions>
            <Button variant='outlined' dsOnClick={() => setOpenManualSync(false)}>Cancel</Button>
            <Box sx={{ m:1, position: 'relative' }}>
              <Button
                disabled={syncInProgress}
                dsOnClick={async () => {
                  if (syncStartDate == null) {
                    alert('Sync Start Date is required!!!');
                    return;
                  }

                  if (syncEndDate == null) {
                    alert('Sync End Date is required!!!');
                    return;
                  }

                  if (selectedObjects.length === 0) {
                    alert('Select an object to sync!!!');
                    return;
                  }

                  try {
                    setSyncInProgress(true);
                    setToast({ ...toast, show: false });
                    
                    const objectTypes = [];
                    const objectMap = selectedTab == 0 ? OBJECTS_MAP : PRODUCTS_MAP;
                    if (selectedObjects && selectedObjects.length > 0) {
                      selectedObjects.forEach((obj) => {
                        objectTypes.push(objectMap[obj]);
                      });
                    }

                    const cookies: any[] = (connect.current_user as any).cookies || [];

                    let zuoraTenantIdFromCookie = '';
                    let zuoraUserIdFromCookie = '';

                    cookies.forEach((cookie) => {
                      if (cookie[0] === 'Zuora-Tenant-Id') {
                        zuoraTenantIdFromCookie = cookie[1];
                      }

                      if (cookie[0] === 'Zuora-User-Id') {
                        zuoraUserIdFromCookie = cookie[1];
                      }
                    });

                    const response = await Connect.proxyCall(
                      `${dacoProxyPath}/cauldron-manual-sync`,
                      'POST',
                      {
                        objectTypes,
                        fromTime: syncStartDate.toString().slice(0, 23) + 'Z',
                        toTime: syncEndDate.toString().slice(0, 23) + 'Z'
                      },
                      {
                        'Content-Type': 'application/json',
                        'Zuora-Tenant-Id': window.connect?.tenant?.tenant_id || zuoraTenantIdFromCookie,
                        'Zuora-User-Id': zuoraUserIdFromCookie
                      }
                    )

                    if (response.status === 400) {
                      setSyncInProgress(false);
                      setToast({ ...toast, show: true, msg: 'Failed to submit a sync request due to a malform payload.', severity: 'error' });
                      return;
                    }

                    if (response.status === 417) {
                      setSyncInProgress(false);
                      setToast({ ...toast, show: true, msg: 'A sync of selected object(s) is currently running. Please try again later!!!', severity: 'warning' });
                      return;
                    }

                    setSyncInProgress(false);
                    setToast({ ...toast, show: true, msg: 'Successfully submitted a sync request.', severity: 'success' });
                  } catch (error) {
                    Connect.log(error);
                    setSyncInProgress(false);
                    setToast({ ...toast, show: true, msg: 'Failed to submit a sync request. Please try again later!', severity: 'error' });
                  }
                }}
              >
                Sync Now
              </Button>
              {
                syncInProgress &&
                <CircularProgress
                  size={24}
                  sx={{
                    color: designTokens.colors.blue500,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              }
            </Box>
          </ModalActions>
        }
        disableBackdropClick
        fullWidth
      />
    </>
  );
};

export default ManualSync;
