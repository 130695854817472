import { Box, Button, Checkbox, CircularProgress, Divider, List, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Alert, Card, Chip, RadioGroup, Radio, Select, SelectItem, Grid, Typography, designTokens } from '@platform-ui/design-system';

import React, { Dispatch, useEffect, useState } from 'react';
import Connect from "../../../../Connect/Connect";
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { useFlipperHook } from '../../../../Tables/helpers';
import { useToast } from '../../../../ToastProvider';
import ManualSync from './ManualSync';
import { useIdentity } from '../../../../IdentityProvider';
import Reconciliation from './Reconciliation';

declare global {
  interface Window {
    connect: any;
  }
}

interface objectEnablement {
  object: string;
  enabled: boolean;
  scheduled: boolean;
}

interface syncSchedule {
  value: string;
}

interface productCatalogEnablementData {
  group: string;
  enabled: boolean;
  objects: string[];
}

interface reconJob {
  id: string;
  startTime: string;
  finishTime: string;
  status: string;
  sourceDiffCount: number;
  sourceDiffLink: string;
  targetDiffCount: number;
  targetDiffLink: string;
}

const LoadingButton = ({
  text = 'Save',
  disabled,
  onClick,
  loading
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        variant='contained'
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </Button>
      {
        loading &&
        <CircularProgress
          size={24}
          sx={{
            color: designTokens.colors.blue500,
            position: 'absolute',
            top: '50%',
            left: '33px',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      }
    </Box>
  );
};

function SyncSettings() {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const tabName = 'syncSettings';
  const accountObjectIndents = new Map<string, number>([
    ['Account', 2],
    ['Contact', 4],
    ['Product', 4],
    ['UnitOfMeasure', 4],
    ['PaymentGateway', 4],
    ['PaymentTerm', 4],
    ['Invoice', 4],
    ['InvoiceItem', 6],
    ['TaxationItem', 8],
    ['PaymentMethod', 4],
    ['Payment', 6],
    ['InvoicePayment', 8],
    ['PaymentPart', 8],
    ['Subscription', 4],
    ['RatePlan', 6],
    ['RatePlanCharge', 8],
    ['SubscriptionHistory', 4],
    ['RatePlanHistory', 6],
    ['RatePlanChargeHistory', 8],
    ['RatePlanChargeTierHistory', 10],
    ['Refund', 4],
    ['RefundPart', 6],
    ['RefundInvoicePayment', 6],
    ['CreditMemo', 4],
    ['CreditMemoItem', 6],
    ['CreditMemoPart', 6],
    ['DebitMemo', 4],
    ['DebitMemoItem', 6],
    ['PaymentSchedule', 4],
    ['PaymentScheduleItem', 6],
  ]);

  const allAccountObjects = Array.from(accountObjectIndents.keys());

  const allProductCatalogObjects = [
    'BillingEntity',
    'Feature',
    'Product2',
    'ProductFeature',
    'ProductRatePlan',
    'ProductRatePlanCharge',
    'ProductRatePlanChargePriceSummary',
    'ProductRatePlanChargeTier',
    'ZProduct',
    'ZUnitOfMeasure',
  ];

  const syncSubheader = 'Use this page to push Zuora information into Salesforce.';
  const reconSubheader = 'Compare recent records created in Zuora against those synced to SFDC';

  const enablementSubheader = (<div>This page allows you to control what objects are synced through the Zuora connector for Salesforce CRM.<br/>
  For more information, visit the <a href='https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connector_for_Salesforce_CRM'>Zuora Connector for Salesforce CRM knowledge Center</a>.<br/>
  Please allow 15 minutes after saving any changes before verifying that your selected objects are syncing.
  </div>);

  const [manualSyncEnabled, _] = useFlipperHook('ih_enable_manual_sync');
  const [hideLegacyChip, _1] = useFlipperHook('hide_legacy_ui_chip');
  const [openManualSync, setOpenManualSync] = useState(false);
  const [openRecon, setOpenRecon] = useState(false); 
  const { setToast } = useToast();
  const [savingAllObjects, setSavingAllObjects] = useState(false);
  const { hasPermission } = useIdentity();
  const [selectedSchedule, setSelectedSchedule] = useState('interval:01H');
  const [syncType, setSyncType] = useState('nearRealTime');
  const dacoProxyPath = `${window.location.host.includes('local') ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2/datacon`;
  const [userId, setUserId] = useState('');

  const savingAllObjectsHandler = async () => {
    try {

      setSavingAllObjects(true);

      const enabledObjects = [];
      const scheduled = syncType === 'scheduled';
      Object.keys(state.settingsHash[tabName] || {}).forEach(key => {
        if (allAccountObjects.includes(key) || allProductCatalogObjects.includes(key)) {
          enabledObjects.push({
            object: key,
            enabled: state.settingsHash[tabName][key],
            scheduled: state.settingsHash[tabName][key] && scheduled
          });
        }
      });

      const headers = {
        'Content-Type': 'application/json',
        'Zuora-Tenant-Id': window.connect?.tenant?.tenant_id,
        'Zuora-User-Id': userId
      };

      let response = await Connect.proxyCall(
        dacoProxyPath + '/tenant-metadata',
        'PUT',
        enabledObjects,
        headers
      );
      
      if (!response.ok) {
        const { message } = await response.json();
        throw Error(message);
      }

      if (scheduled) {
        response = await Connect.proxyCall(
          dacoProxyPath + '/tenant-metadata/schedule',
          'PUT',
          {
            value: selectedSchedule
          },
          headers
        );

        if (!response.ok) {
          const { message } = await response.json();
          throw Error(message);
        }
      }

      setToast({
        message: 'Sync settings was successfuly updated.',
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setToast({
        message: err.message || 'Error enabling objects',
        severity: 'error'
      });
    } finally {
      setSavingAllObjects(false);
    }
  };

  const handleDispatch = (type: string, payload: any) => {
    dispatch({ type, payload });
  };

  const handleClickObject = (object: string) => {
    const updatedSettings = {
      ...(state.settingsHash[tabName] || {}),
      [object]: !enabled(object)
    };
    handleDispatch(tabName, updatedSettings);
  };

  const handleClickRecon = (jobs: reconJob[]) => {
    if (jobs.length > 0) {
      let now = new Date();
      if (jobs[0].finishTime == null && now.getDay() === new Date(Date.parse(jobs[0].startTime)).getDay()) {
        alert('Please wait for the previous job to complete before starting another job');
        return;
      }
      if (jobs[0].finishTime != null && now.getDay() === new Date(Date.parse(jobs[0].finishTime)).getDay()) {
        alert('Only one recon job can be run per day! Please try again later')
        return;
      }
    }
    setOpenRecon(true);
  }

  function updateObjectsEnablementStatuses(objects: string[]) : void {
    // Note(Duc): We do not want to show unsaved changes if there's a mismatch between what we store and what daco returns. We just need to merge these two lists.
    const updatedSettings = { ...state.settingsHash[tabName], merge: true };
    const enabled : boolean = !allObjectsAreEnabled(objects);
    objects.forEach((object: string) => {
      updatedSettings[object] = enabled;
    });

    handleDispatch(tabName, updatedSettings);
  }

  function enabled(item: string) : boolean {
    return !!state.settingsHash[tabName]?.[item];
  }

  function retrieveReconJobs() : reconJob[] {
    let result = state.settingsHash[tabName]?.['reconJobData'];
    if (result == null) {
      return [];
    }
    return result;
  }

  function allObjectsAreEnabled(objects: string[]) : boolean {
    for (const objectType of objects) {
      if (!enabled(objectType)) {
        return false;
      }
    }
    return true;
  }

  function noObjectsAreEnabled(objects: string[]) : boolean {
    for (const objectType of objects) {
      if (enabled(objectType)) {
        return false;
      }
    }
    return true;
  }

  function addSpaceToCamelCase(input: string) : string {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  function waitFor(variable, callback) {
    var interval = setInterval(function() {
      if (window[variable]) {
        clearInterval(interval);
        callback();
      }
    }, 200);
  }

  async function fetchObjectsEnablementData(headers: any) : Promise<objectEnablement[]> {
    try {
      const url = dacoProxyPath + '/tenant-metadata/metadata-ui-only';
      const response : Response = await Connect.proxyCall(url, 'GET', null, headers);
      if (!response) throw new Error('No response received');
      if (!response.ok) throw Error(response['message'] || `Unsuccessful response received`);
      return await response.json();
    } catch (error) {
      Connect.log(error);
      // return an empty array to error out silently instead of blocking the execution
      return []
    }
  }


  async function fetchScheduleConfig(headers: any) : Promise<syncSchedule> {
    try {
      const url = dacoProxyPath + '/tenant-metadata/schedule';
      const response : Response = await Connect.proxyCall(url, 'GET', null, headers);
      if (!response) throw new Error('No response received');
      if (!response.ok) throw Error(response['message'] || `Unable to fetch tenant schedule config. Unsucessful response!`);
      return await response.json();
    } catch (error) {
      Connect.log(error);
      return null;
    }
  }

  async function fetchReconJobData(headers: any) : Promise<reconJob[]> {
    // This should never return more than a week's worth of data (~7 rows)
    try {
      const url = `${window.location.host.includes('local') ? 'http://localhost:8070' : ''}/sync/internal/recon`;
      const response : Response = await Connect.proxyCall(url, 'GET', null, headers);
      if (!response) throw new Error('No response received');
      if (!response.ok) throw Error(response['message'] || `Unsuccessful response received`);
      return await response.json();
    } catch (error) {
      Connect.log(error);
      // return a default object to error out silently instead of blocking the execution
      return [];
    }
  }

  async function updateObjectEnablementData(enablements: objectEnablement[]) {
    // Expected response format: [{"object":"Account","enabled":true},{"object":"Contact","enabled":true}]
    try {
      const newSettings = {};
      enablements.forEach(item => {
        if (enabled(item.object) !== item.enabled) {
          newSettings[item.object] = item.enabled;
        }
      });
      if (Object.keys(newSettings).length > 0) {
        // Note(Duc): We do not want to show unsaved changes if there's a mismatch between what we store and what daco returns. We just need to merge these two lists.
        newSettings['merge'] = true;
        handleDispatch(tabName, newSettings);
      }
    } catch (error) {
      // log the error and error out silently
      Connect.log(error);
    }
  }


  async function updateReconJobTableData(jobs: reconJob[]) {
    // Expected response format: 
    // [
    //   {
    //     "id":"0e3ae1c8-5ffc-4739-82dd-4e62afdcc089",
    //     "tenantId":"11763",
    //     "startTime":"2025-01-10T02:06:15.327Z",
    //     "finishTime":"2025-01-10T03:06:15.327Z",
    //     "status":"FINISHED",
    //     "sourceDiffCount":1234,
    //     "sourceDiffLink":http://11763_dummy_download_sourceNotTarget.com,
    //     "targetDiffCount":5678,
    //     "targetDiffLink":http://11763_dummy_download_targetNotSource.com,
    //     "updatedOn":"2025-01-10T02:06:15.383Z"
    //   }
    // ]
    try {
      const newJobs = {};
      newJobs['reconJobData'] = [];
      jobs.forEach(job => {
        newJobs['reconJobData'].push(job);
      });
      if (Object.keys(newJobs).length > 0) {
        handleDispatch(tabName, newJobs);
      }
    } catch (error) {
      // log the error and error out silently
      Connect.log(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      while (!window.hasOwnProperty('connect')) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      waitFor('connect', async function () {
        const cookies: any[] = (window.connect.current_user as any).cookies || [];
        let zuoraTenantIdFromCookie = '';
        let zuoraUserIdFromCookie = '';
        cookies.forEach((cookie) => {
          if (cookie[0] === 'Zuora-Tenant-Id') {
            zuoraTenantIdFromCookie = cookie[1];
          }

          if (cookie[0] === 'Zuora-User-Id') {
            zuoraUserIdFromCookie = cookie[1];
          }
        });
        setUserId(zuoraUserIdFromCookie);

        const headers = {
          'Content-Type': 'application/json',
          'Zuora-Tenant-Id': window.connect?.tenant?.tenant_id || zuoraTenantIdFromCookie,
          'Zuora-User-Id': zuoraUserIdFromCookie
        };

        fetchObjectsEnablementData(headers)
          .then((enablements: objectEnablement[]) => {
            updateObjectEnablementData(enablements);
            // Sync type is only scheduled if every enabled object is scheduled.
            const computedSyncType = enablements.filter(enablement => enablement.enabled).every(enablement => enablement.scheduled) ? 'scheduled' : 'nearRealTime';
            setSyncType(computedSyncType);
            if (computedSyncType === 'scheduled') {
              fetchScheduleConfig(headers).then(setting => setSelectedSchedule(setting === null || 'none' === setting.value.toLocaleLowerCase() ? 'interval:01H' : setting.value))
            }
          });

        fetchReconJobData(headers)
          .then((jobs: reconJob[]) =>
            updateReconJobTableData(jobs));
      });
    };

    fetchData();
  }, []);

  if (!state.credsModified) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must set up credentials in Authentication tab first' open={true} />
    );
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Card
          id='zdp-sync-settings-top-ui'
          body={
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid container item xs direction='column' spacing={0.5}>
                    <Grid container item alignItems='center' spacing={1}>
                      <Grid item><Typography variant='h5'>Manual Sync</Typography></Grid>
                    </Grid>
                    <Grid item><Typography variant='body2'>{syncSubheader}</Typography></Grid>
                  </Grid>
                  <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
                    <ManualSync openManualSync={openManualSync} setOpenManualSync={setOpenManualSync} />
                    { manualSyncEnabled && <Button variant='contained' onClick={() => setOpenManualSync(true)}>Sync</Button> }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>

      <Grid item>
        <Card
          id='zdp-sync-settings-top-ui'
          body={
            <Grid container>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid container item xs direction='column' spacing={0.5}>
                    <Grid container item alignItems='center' spacing={1}>
                      <Grid item><Typography variant='h5'>Object Enablement { !hideLegacyChip && <Chip size='medium' dsOnClick={() => window.location.href = '/platform/apps/data-connect/object-enablement'} label={<Typography variant='overline'>Go Back to old UI</Typography>} state='info'/> } </Typography> </Grid>
                    </Grid>
                    <Grid item><Typography variant='body2'>{enablementSubheader}</Typography></Grid>
                  </Grid>
                  <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
                    <LoadingButton
                      disabled={savingAllObjects || !state.settingsHash[tabName]}
                      onClick={savingAllObjectsHandler}
                      loading={savingAllObjects}
                    />
                  </Grid>
                </Grid>
                <hr />
                <Grid container direction='row' justify='flex-start'>
                  <Grid item direction='column' xs={6}>
                    <RadioGroup
                      key='sync_mode_rg'
                      label='Sync Type'
                      value={syncType}
                      dsOnChange={(e) => {
                        setSyncType(e.target.value)
                      }}
                    >
                      <Grid container direction='row'>
                        <Grid item>
                          <Radio
                            checked={syncType === 'scheduled'}
                            value='scheduled'
                            label='Scheduled'
                          />
                        </Grid>
                        <Grid item>
                          <Radio
                            checked={syncType === 'nearRealTime'}
                            value='nearRealTime'
                            label='Near Realtime'
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                    </Grid>
                    {syncType === 'scheduled' && (
                      <Grid item direction='column' xs={6}>
                      <Select
                        label='Scheduled Increment'
                        a11yLabel='Scheduled Increment Select'
                        dsOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSelectedSchedule(e.target.value);
                        }}
                        placeholder="Select Increment"
                        required={syncType === 'scheduled'}
                        value={selectedSchedule}>
                        <SelectItem value='interval:10M'>10 Minutes</SelectItem>
                        <SelectItem value='interval:30M'>30 Minutes</SelectItem>
                        <SelectItem value='interval:01H'>1 Hour</SelectItem>
                        <SelectItem value='interval:06H'>6 Hours</SelectItem>
                        <SelectItem value='interval:12H'>12 Hours</SelectItem>
                        <SelectItem value='interval:24M'>24 Hours</SelectItem>
                      </Select>
                      </Grid>
                    )}
                </Grid>
                <hr />
              </Grid>
              <Grid item xs={6}>
                <List
                  sx={{ width: '100%', maxWidth: 720, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton sx={{ pl: 0}} onClick={() => updateObjectsEnablementStatuses(allAccountObjects)}>
                    <ListItemIcon>
                      <Checkbox checked={allObjectsAreEnabled(allAccountObjects)} indeterminate={!noObjectsAreEnabled(allAccountObjects) && !allObjectsAreEnabled(allAccountObjects)} />
                    </ListItemIcon>
                    <ListItemText primary="Account & Related Objects" />
                  </ListItemButton>
                  <Divider />
                  <List component="div" disablePadding>
                    {
                      allAccountObjects.map((object) => {
                        let disabled = false;
                        let tooltip: React.ReactNode = '';
                        if (['CreditMemo', 'CreditMemoItem', 'CreditMemoPart', 'DebitMemo', 'DebitMemoItem'].includes(object)) {
                          disabled = !hasPermission('permission.ARSettlement') && !hasPermission('permission.InvoiceSettlementHarmonization');
                          tooltip = disabled ? <Typography variant='titleS'>ARSettlement and InvoiceSettlementHarmonization are turned off</Typography> : '';
                        }
                        return (
                          <Tooltip title={tooltip} arrow>
                            <div>
                            <ListItemButton disabled={disabled} sx={{ pl: accountObjectIndents.get(object) }} onClick={() => handleClickObject(object)}>
                              <ListItemIcon>
                                <Checkbox checked={enabled(object)} />
                              </ListItemIcon>
                              <ListItemText primary={addSpaceToCamelCase(object)} />
                            </ListItemButton>
                            </div>
                          </Tooltip>
                        );
                      })
                    }
                  </List>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List
                  sx={{ width: '100%', maxWidth: 720, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton onClick={() => updateObjectsEnablementStatuses(allProductCatalogObjects)}>
                    <ListItemIcon>
                      <Checkbox checked={allObjectsAreEnabled(allProductCatalogObjects)} indeterminate={!noObjectsAreEnabled(allProductCatalogObjects) && !allObjectsAreEnabled(allProductCatalogObjects)} />
                    </ListItemIcon>
                    <ListItemText primary="Product Catalog" />
                  </ListItemButton>
                  <Divider />
                  <List component="div" disablePadding>
                    {
                      allProductCatalogObjects.map((object) => (
                        <ListItemButton sx={{ pl: 4 }} disabled={true}>
                          <ListItemIcon>
                            <Checkbox checked={enabled(object)} />
                          </ListItemIcon>
                          <ListItemText primary={addSpaceToCamelCase(object)} />
                        </ListItemButton>
                      ))
                    }
                  </List>
                </List>
              </Grid>
            </Grid>
          }
        />
      </Grid>

      <Grid item>
        <Card
          id='zdp-sync-settings-top-ui'
          body={
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid container item xs direction='column' spacing={0.5}>
                    <Grid container item alignItems='center' spacing={1}>
                      <Grid item><Typography variant='h5'>Reconciliation</Typography></Grid>
                    </Grid>
                    <Grid item><Typography variant='body2'>{reconSubheader}</Typography></Grid>
                  </Grid>
                  <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
                    <Reconciliation openRecon={openRecon} setOpenRecon={setOpenRecon} tabName={tabName} />
                    { <Button variant='contained' onClick={() => handleClickRecon(retrieveReconJobs())}>Start Job</Button> }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <Card
          id='zdp-sync-settings-top-ui'
          body = {
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Job Id</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Start Time</TableCell>
                    <TableCell align="right">Finish Time</TableCell>
                    <TableCell align="right">Source Diff Count</TableCell>
                    <TableCell align="right">Source Diff Download</TableCell>
                    <TableCell align="right">Target Diff Count</TableCell>
                    <TableCell align="right">Target Diff Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {retrieveReconJobs().map((row: reconJob) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right">{row.startTime}</TableCell>
                      <TableCell align="right">{row.finishTime}</TableCell>
                      <TableCell align="right">{row.sourceDiffCount}</TableCell>
                      <TableCell align="right">{row.sourceDiffLink}</TableCell>
                      <TableCell align="right">{row.targetDiffCount}</TableCell>
                      <TableCell align="right">{row.targetDiffLink}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        />
      </Grid>
    </Grid>
  );
}

export default SyncSettings;
